.heatmap {
  display: flex;
  .title-and-map {
    position: relative;
    .world-map {
      aspect-ratio: 1;
      .common-loading {
        height: 330px;
        padding-top: 80px;
      }
    }
    .map-with-markers {
      height: 300px;
    }
    .title {
      font-size: 19px;
      font-weight: bold;
      margin-left: 26px;
      margin-bottom: 17px;
    }
  }

  .legend-and-stats {
    align-self: center;

    .stats {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 30px 30px 0px 30px;
      font-size: 16px;

      div {
        flex: 1 1 80px;
        padding: 0px 5px;
        svg {
          margin: 5px;
        }

        strong {
          display: block;
          color: #a3a1fb;
          font-weight: bold;
          font-size: 1.2em;
        }
        span {
          font-weight: bold;
          margin: 5px 0;
          font-size: 13px;
        }
      }
    }
  }
}
