.customer-data-table {
  header {
    h1 {
      font-size: 21px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }
    .description {
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
    }
  }

  table {
    td:nth-child(1) a {
      display: flex;
      align-items: center;
      text-decoration: none;
      .name {
        text-decoration: underline;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      width: 25%;
      font-weight: bold;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: left;
      width: 25%;
    }
  }

  .export-and-totals {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .total-card {
      text-align: right;
      font-size: 13px;
    }

    .export-data-btn {
      font-weight: normal;
      font-size: 12px;
      padding: 5px;
    }
  }
}
