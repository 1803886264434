.messages {
  .campaign-filter {
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.columns-1 .cards .ui--card {
    width: 100%;
  }

  &.columns-2 .cards .ui--card {
    width: 50%;
  }

  &.columns-3 .cards .ui--card {
    width: 33%;
  }

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .message-card {
      height: 400px;
      margin: 0px;
      padding: 10px;

      &.loading {
        .content {
          display: flex;
          align-items: center;
        }
      }
      .content {
        padding: var(--base-space-2x);
      }
    }
  }
}
