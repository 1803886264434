.stats-card-simple {
  height: 140px;
  font-size: 13px;

  &.clickable {
    cursor: pointer;
  }

  .content {
    padding: var(--base-space-4x) var(--base-space-2x);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .app-totals {
    display: flex;

    .figure {
      margin: auto;
      svg {
        width: 62px;
        height: 60px;
      }
    }

    .total-numbers {
      width: 100%;
      margin-left: 24px;
      align-self: center;
      strong {
        font-size: 20px;
      }
      span {
        font-size: 12px;
      }

      .stats {
        margin-top: 6px;
        min-width: max-content;
        .items {
          font-size: x-small;
          margin-right: 5px;
          font-weight: 400;

          .itemStats {
            margin-right: 5px;
            color: #a4a6b5;
            font-weight: normal;
          }
        }
      }
    }
  }

  strong {
    display: block;
  }

  span {
    font-weight: bold;
    color: #4d4f5c;
  }
}

@media screen and (max-width: 960px) {
  .stats-card-simple {
    min-width: 45%;

    .content {
      padding: var(--base-space-2x);
      font-size: 15px;

      .items {
        display: none;
      }

      .dots {
        display: none;
      }
    }
  }
}
