.time-location-filters {
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 10px 0px;
  .filter-item {
    margin-right: 0.8em;
  }
  .filter {
    width: 10em;
  }

  .hour-picker {
    .selector {
      line-height: 20px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
      color: var(--font-color);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .icon-left {
        margin-right: 10px;
      }
      .value {
        min-width: 80px;
      }
      .icon-right {
        margin-left: 10px;
      }
    }

    .hours {
      position: absolute;
      z-index: 64;
      border-radius: 4px;
      padding: 1px;
      text-align: center;
      .container {
        display: flex;
        justify-content: flex-start;
        height: 16.7rem;
        padding: 0px;
        & > div {
          flex: 0 0;
        }
      }
    }
  }
}
