.select__control {
  & > svg {
    width: 30px;
    path {
      fill: var(--button-background);
    }
  }
}

.select__control {
  cursor: pointer;
  position: relative;
  &.select__control--is-focused {
    outline: 0;
    border-width: 1px;
    box-shadow: none;
  }
  .select__input-container {
    flex: 0 0 0px;
  }
}

.select__clear {
  font-style: italic;
  color: var(--font-color-light);
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: var(--button-background-a01);
  }
}

.select__group-heading {
  padding-bottom: 8px;
}

.select__placeholder,
.select__multi-value,
.select__single-value {
  flex: 1 1 100%;
  font: var(--font);
  color: var(--font-color);
  white-space: nowrap;
}

.select__input {
  margin-left: 11px;
}

.filter {
  .select__control {
    .select__value-container {
      .select__single-value,
      .select__multi-value,
      .select__placeholder {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .select__indicators {
      .select__dropdown-indicator {
        padding-right: 14px;
      }
    }
  }

  .select__control--menu-is-open {
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }

  .select__menu {
    width: auto;
    min-width: 100%;
    z-index: 8000;
    margin-top: -3px;
    border-top: 0px;
    box-shadow: none;
    background: transparent;
    clip: rect(0px, 1000px, 1000px, -1000px);
    .select__menu-list {
      max-height: 500px;
      background: white;
      padding-top: 0px;
      border-radius: 0px 0px 4px 4px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
      .select__clear,
      .select__option {
        font-size: 13px;
        padding-left: 20px;
        padding-right: 10px;
        white-space: nowrap;
      }
    }
  }

  &.invalid {
    border-bottom: 2px solid var(--error-color);
  }
}

.select {
  .select__control {
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    &.select__control--is-disabled {
      background-color: var(--input-background-color);
      .select__placeholder {
        display: none;
      }
      .select__indicators {
        display: none;
      }
    }
    &.select__control--menu-is-open {
      .select__placeholder {
        display: none;
      }
    }
  }
  &.invalid .select__control {
    border: 1px solid var(--error-color);
  }
}

div.select__menu {
  z-index: 2;
  &.rightmost {
    right: 0px;
  }
  .select__menu-list {
    .select__option--is-selected {
      background-color: var(--button-background-a05);
      &.select__option--is-focused {
        background-color: var(--button-background-a05);
      }
      &.select__option:hover {
        background-color: var(--button-background-a05);
      }
    }
    .select__option--is-focused {
      background-color: var(--button-background-a01);
    }
    .select__option:hover {
      background-color: var(--button-background-a01);
    }
    .select__menu-notice {
      user-select: none;
      cursor: normal;
      opacity: 0.5;
      text-align: center;
      padding: 10px;
    }
  }
}

.select__option {
  .deactivated {
    color: gainsboro !important;
  }

  .description {
    white-space: pre;
    text-overflow: clip;
    font-size: 0.8em;
    opacity: 0.8;
  }

  &.multiple {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    cursor: pointer;

    .description {
      padding-left: 2em;
    }

    .checkbox {
      position: relative;
      transition: background-color 0.3s ease-in-out;
      background-color: transparent;
      border-radius: 3px;
      border: 2px solid var(--button-background);
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-right: 12px;
      margin-left: -6px;
      top: 3px;
      outline: none !important;

      &.checked {
        background-color: var(--button-background-a09);
        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 8px;
          top: 1px;
          left: 4px;
          display: block;
          border: 2px solid white;
          border-top-width: 0;
          border-left-width: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
