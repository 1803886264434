.excel-upload-area {
  padding: 28px;

  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #file-input,
  .hide-loading {
    display: none;
  }

  .loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
  }

  button {
    svg {
      top: 2px;
      width: 1.6em;
      height: 1.2em;
      position: relative;
      path {
        fill: var(--button-background-light-inverse);
        opacity: 1;
      }
    }
  }

  .instructions-one {
    font-weight: 500;
    margin-bottom: 13px;
  }

  .step-one-extra {
    margin-top: 15px;
  }

  .instructions-two {
    font-weight: 500;
    margin: 40px 0px 13px 0px;
  }

  .settings {
    margin-bottom: 10px;
  }

  .footer {
    margin-top: 10px;
  }

  .excel-table {
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    font-family: Calibri;
    font-weight: 100;
    border-spacing: 0px;
    border-collapse: collapse;
    width: 98%;
    overflow: scroll;

    th {
      padding: 3px;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      border: 1px solid #9eb6ce;
      border-width: 0px 1px 1px 0px;
      height: 13px;
      line-height: 13px;
    }

    td {
      background-color: white;
      padding: 4px;
      font-size: 12px;
      border: 1px solid #d0d7e5;
      border-width: 0px 1px 1px 0px;
      width: 1%;
      white-space: nowrap;
      height: 13px;
      line-height: 13px;
    }

    td b {
      background-color: white;
      font-weight: bold;
    }

    td.excel-headers {
      background-color: #e4ecf7;
      text-align: center;
      border: 1px solid #9eb6ce;
      border-width: 0px 1px 1px 0px;
      font-weight: bold;
    }
  }

  .default-lists {
    margin-bottom: 10px;
  }

  .mapping-lists .drag-item {
    font-size: 15px;
    font-weight: bold;
  }
}
