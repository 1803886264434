.customer-tracking {
  min-height: 170px;

  h2 {
    color: var(--font-color-light);
    font-size: 18px;
  }

  .stats {
    display: flex;
    flex-direction: row;

    .title {
      font-weight: bold;
    }
    .hidden {
      display: none;
    }
    .footer {
      margin-top: 24px;
      text-align: left;
    }
    ul {
      flex: 1 1;
      margin-top: 12px;

      li {
        display: block;
        margin-bottom: 4px;

        i {
          display: block;
          font-size: 12px;
          padding-left: 60px;
          margin-bottom: 4px;
        }
        .ping-type {
          display: inline-block;
          width: 60px;

          &.in {
            color: var(--highlight-green);
          }
          &.out {
            color: var(--highlight-red);
          }
          &.range {
            color: var(--highlight-purple);
          }
        }
      }
    }
  }
}