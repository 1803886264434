@media screen and (min-width: 760px) {
  .beacon-form {
    width: 700px;
  }
}

.beacon-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: 16px;
  padding: 40px;

  form {
    margin-top: 20px;
  }

  .major-minor {
    .major,
    .minor,
    .type {
      width: 25%;
    }
    .cashbox {
      width: 20%;
    }
  }

  .locations {
    display: flex;
    justify-content: flex-start;
    .x-coordinate {
      width: 15%;
    }
    .y-coordinate {
      width: 15%;
      margin-left: 20px;
    }
  }

  .area {
    margin-bottom: 0px;
    
    .form-input:nth-child(1) {
      flex: 1 1 200px;
    }
    .form-input:nth-child(2) {
      flex: 1 1 100%;
    }
  }

  .hide-input {
    display: none;
  }
}
