.time-location-filters {
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 10px 0px;
  .filter-item {
    margin-right: 0.8em;
  }
  .filter {
    width: 10em;
  }
}
