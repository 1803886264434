.message-card {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .message-image {
      height: 100%;
      flex: 0 0;
      .title,
      .text,
      .button-bar {
        font-size: 9px !important;
      }
    }

    .message-details {
      flex: 1 1;
      height: 100%;
      font-size: 13px;
      font-family: Arial;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px 6px 6px 32px;

      .status {
        position: relative;
        flex: 0 0 max-content;
        min-height: 70px;
        max-height: 130px;
        overflow: hidden;

        strong {
          font-size: 12px;
        }
        .type {
          margin-left: 18px;
          margin-right: 13px;
          color: var(--font-color-light);
        }
        .audience-total {
          margin-top: 10px;
        }
        .audience-display {
          margin-top: 5px;
          overflow: visible;
        }
      }

      .description {
        flex: 1 1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35px);
        padding-top: 40px;
        margin-top: -40px;
        max-height: 200px;
        overflow: hidden;
        z-index: 1;

        h1 {
          font-size: 20px;
          font-weight: bold;
          color: var(--message-title-color);
        }

        .title {
          margin: 20px 20px 5px 0px;
        }
        .text {
          line-height: 1.7;
          font-size: 13px;
          font-weight: bold;
          color: var(--message-text-light);
          overflow: hidden;
          max-height: 133px;
        }
        .schedule {
          font-size: 12px;
          font-weight: bold;
          color: var(--message-text-dark);
        }
      }

      .buttons {
        flex: 0 1;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
          font-weight: bold;
          font-size: 14px;
        }
        .delete {
          color: #ed585e;
        }
        .delete:hover {
          color: orangered;
        }
        .duplicate {
          color: #676974;
        }
        .edit {
          color: #2580ec;
        }
      }
    }
  }

  .status-top {
    display: flex;
    align-items: center;

    .mute-icon,
    .converted-icon,
    .converting-icon {
      margin-right: 5px;
    }
    .converting-icon {
      margin-top: 1px;
    }
  }
}

@media screen and (max-width: 960px) {
  .message-card {
    min-width: 100%;

    .content {
      padding: var(--base-space-2x);
      font-size: 15px;
      margin: 0 30px 10px 10px;

      .items {
        display: none;
      }

      .dots {
        display: none;
      }
    }
  }
}
