.early-bird-visitors-data-table table {
  td:nth-child(1) a {
    display: flex;
    align-items: center;
    text-decoration: none;
    .name {
      text-decoration: underline;
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    width: 25%;
    font-weight: bold;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }

  th:nth-child(3),
  td:nth-child(3) {
    text-align: left;
    width: 25%;
  }
}
