.property-changes-table {
  header {
    h1 {
      font-size: 21px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }
    .description {
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
      max-width: 75vw;
    }
  }
  table {
    td:nth-child(1) {
      padding-left: 15px !important;
    }

    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      text-align: left;
      width: 33%;
    }
  }
}
