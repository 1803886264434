.top-visitors {
  table {
    img {
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      margin-left: 3px;
    }

    th:nth-child(1) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: left;
      span {
        display: flex;
        align-items: center;
        font-weight: bold;
        svg {
          margin-right: 15px;
        }
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: right;
    }

    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }
  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
