.notification-campaigns {
  .clickable {
    cursor: pointer;
    text-decoration: underline;
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4),
  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6),
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(8),
  td:nth-child(8),
  th:nth-child(9),
  td:nth-child(9),
  th:nth-child(10),
  td:nth-child(10),
  th:nth-child(11),
  td:nth-child(11) th:nth-child(12),
  td:nth-child(12) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 130px;
    width: 5%;
  }

  td:nth-child(1) {
    span {
      display: flex;
      align-items: center;

      img {
        height: 50px;
        width: 40px;
        object-fit: cover;
        object-position: center center;
        margin-right: 20px;
        margin-left: 2px;
      }
    }
  }
}
