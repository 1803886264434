.floorplan-upload {
  &.invalid {
    button {
      color: var(--error-color);
      border: 1px solid var(--error-color);
      background-color: var(--error-background);
      &:hover:not(:disabled) {
        background-color: var(--error-background);
      }
      svg path {
        fill: var(--error-color);
        opacity: 0.5;
      }
    }
  }
  button {
    svg {
      top: 2px;
      width: 1.6em;
      height: 1.2em;
      position: relative;
      path {
        fill: var(--button-background-light-inverse);
        opacity: 1;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
  .instruction {
    margin-left: 10px;
    color: var(--font-color);
    font-size: 0.8em;
  }
  .error {
    margin-left: 10px;
    color: var(--error-color);
    font-size: 0.8em;
    display: inline;
    left: 0px;
    top: 0px;
    padding: 0px;
  }
}
