@import "date-picker/DatePicker.Variables.scss";

.schedule-picker {
  &.select {
    .select__indicators {
      margin-right: 5px;
    }
    svg.input-icon {
      margin-left: 9px;
      cursor: pointer;
      height: 17px;
      width: 17px;
      path {
        opacity: 1;
      }
    }
  }
  .schedule-date-picker {
    position: absolute;
    z-index: 64;
    left: 0px;
    top: 44px;
    border-radius: 4px;
    border: 1px solid $datepicker__border-color;
    background-color: $datepicker__background-color;
    padding: 1px;
    text-align: center;
    .container {
      display: flex;
      justify-content: flex-start;
      height: 16.7rem;
      padding: 0px;
      & > div {
        flex: 0 0;
      }
    }
    .save,
    .close {
      cursor: pointer;
      text-align: center;
      min-width: 60px;
      width: 60px;
      font-size: 14px;
      border-top: 1px solid $datepicker__background-color;
      font-weight: bold;
      padding: 3px;
      height: 32px;
      margin: 4px;
    }
    .react-datepicker {
      border-radius: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $datepicker__border-color;
      &__header--time--only {
        padding: 1.26rem 0.5rem;
      }
      &__time {
        border-left: 1px solid $datepicker__border-color;
      }
    }
  }
}
