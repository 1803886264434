.segments {
  &.columns-1 .cards .ui--card {
    width: 100%;
    height: 400px;
    margin: 0px;
    padding: 10px;
  }

  &.columns-3 .cards .ui--card {
    width: 33.333%;
    height: 400px;
    margin: 0px;
    padding: 10px;
  }

  &.columns-2 .cards .ui--card {
    width: 50%;
    height: 400px;
    margin: 0px;
    padding: 10px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .customer-segment-card {
      &.loading {
        .content {
          display: flex;
          align-items: center;
        }
      }
      .content {
        padding: var(--base-space-2x);
      }
    }
  }
}

.customer-segment-preview {
  .segment-bar {
    fill: var(--beacon-blue);
    stroke: var(--beacon-blue-darker);
    text {
      stroke-width: 0px;
      fill: white;
    }
  }
}
