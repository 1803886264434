@import "date-picker/DatePicker.Variables.scss";

.validity-picker {
  &.select {
    .select__indicators {
      margin-right: 5px;
    }
    svg.input-icon {
      margin-left: 9px;
      cursor: pointer;
      height: 17px;
      width: 17px;
      path {
        opacity: 1;
      }
    }
  }
  .react-datepicker {
    position: absolute;
    z-index: 64;
    left: 0px;
    top: 44px;
    border-radius: 4px;
    border: 1px solid $datepicker__border-color;
    text-align: center;
  }
}
