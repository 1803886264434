.daily-visiting-analytics {
  .day {
    width: 30%;
  }

  th:nth-child(1),
  th:nth-child(2),
  td:nth-child(1),
  td:nth-child(2) {
    text-align: left;
    font-weight: bold;
  }
}
