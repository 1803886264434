.redemption-users-table {
  table {
    td:nth-child(1) a {
      display: flex;
      align-items: center;
      text-decoration: none;
      .name {
        text-decoration: underline;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      width: 25%;
      font-weight: bold;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4) {
      text-align: left;
      width: 20%;
    }
  }

  .column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .export-data-btn {
      width: 90%;
    }
  }
}
