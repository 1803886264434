.drag-item {
  font-size: 12px;
  background-color: #f5f6fa;
  border-radius: 4px;
  padding: 4px 8px;
  transition: background-color 0.8s ease-out;
  margin: 3px;
  &.invalid {
    color: var(--error-color);
  }
}

.drag-item:hover {
  background-color: #ffffff;
  transition: background-color 0.1s ease-in;
}
