.top-customers {
  table.with-image {
    img {
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      margin-left: 3px;
    }

    th:nth-child(1) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: left;
      padding-right: 0px;
      .profile-info {
        display: flex;
        align-items: center;
        .name {
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: right;
      padding-left: 0px;
    }

    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }

  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
