.list-header {
  margin-right: 10px;
  flex: 0 0 120px;
}

.drop-box {
  display: flex;
  align-items: center;
}

.drop-list {
  border-radius: 8px;
  padding: 5px;
  justify-content: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .drag-item {
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.fixed-width {
  background-color: #f5f6fa;
  min-width: 100%;
  min-height: 28px;
}
