.shopper-trends table {
  th:nth-child(1) {
    padding-left: 10px;
  }
  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    width: 62px;
    min-width: 62px;
    max-width: 62px;
    img {
      margin-left: 2px;
      height: 50px;
      width: 40px;
      object-fit: cover;
      object-position: center center;
    }
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }

  th:nth-child(3),
  td:nth-child(3) {
    text-align: right;
  }

  td:nth-child(2) {
    font-weight: bold;
  }
}
