.edit-message {
  transition: height;
  padding: 40px;
  min-width: 600px;

  .header {
    margin-bottom: 40px;
  }

  .loading {
    height: 600px;
    display: flex;
    justify-content: center;
  }

  .padleft-3px {
    padding-left: 3px !important;
  }
}
