.map-with-markers {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .markers {
    overflow: hidden;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      user-select: none;
      cursor: default;
    }
    .marker {
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
}

.marker {
  &.red {
    .filled {
      fill: var(--highlight-red);
    }
    .stroked {
      stroke: var(--highlight-red);
    }
  }
  &.green {
    .filled {
      fill: var(--highlight-green);
    }
    .stroked {
      stroke: var(--highlight-green);
    }
  }
  &.orange {
    .filled {
      fill: var(--highlight-orange);
    }
    .stroked {
      stroke: var(--highlight-orange);
    }
  }
  &.lightblue {
    .filled {
      fill: var(--highlight-lightblue);
    }
    .stroked {
      stroke: var(--highlight-lightblue);
    }
  }
  &.yellow {
    .filled {
      fill: var(--highlight-yellow);
    }
    .stroked {
      stroke: var(--highlight-yellow);
    }
  }
}
