.edit-floorplan-pdf {
  margin: 0 auto;
  width: 70vw;
  padding: 40px;
  box-sizing: content-box;
  position: relative;

  .header {
    margin-bottom: 40px;
  }
  .footer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .full-progress {
    position: absolute;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;
    .lds-dual-ring {
      margin: 0 auto;
      display: block;
      width: 400px;
      height: 400px;
      top: 50%;
      margin-top: -200px;
      position: relative;
      &::after {
        border-width: 60px;
      }
    }
  }
  .floor-preview {
    margin: 0 auto 28px auto;
    border-radius: 6px;
    border: 3px solid var(--border-color);
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    .react-pdf {
      &__Page {
        margin: 0 auto;
      }
    }
    .toolbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-color);
      padding: 0px 10px;
      height: 54px;
      .form-input {
        margin-top: 2px;
        width: 200px;
      }
      button {
        line-height: 26px;
      }
      span {
        color: var(--font-color);
        font-size: 0.9em;
      }
    }
    .editor {
      width: 100%;
      position: relative;
      .overlay {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .common-loading {
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }
    &.selected {
      border-color: var(--beacon-blue);
      &.invalid {
        border-color: var(--error-color);
      }
    }
    &.loading canvas {
      opacity: 0.1;
    }
  }
}
