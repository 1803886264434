.sales-table table {
  th:nth-child(1) {
    text-align: left;
    width: 35%;
    padding-left: 30px;
  }

  td:nth-child(1) {
    text-align: left;
    font-weight: bold;
  }
}
