.full-world-map {
  z-index: 10;

  .marker {
    &.active svg path,
    &.open svg path {
      fill: var(--highlight-green);
    }
    &.deactivated svg path,
    &.closed svg path {
      fill: var(--highlight-red);
    }
    svg {
      width: 24px;
      transform: translate(-50%, -100%);
      filter: drop-shadow(0px 0px 3px #00000050);
    }
  }
}
