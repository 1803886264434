.visitor-statistics {
  .analytics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ui--card {
      flex: 1 0 250px;
    }
  }

  .charts-and-visitors {
    margin: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .age-chart,
    .gender-chart {
      flex: 1 0 200px;
    }

    .top-visitors {
      flex: 2 0 400px;
    }
  }
}
