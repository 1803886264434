.page-login {
  display: flex;
  height: 100vh;

  .login-form,
  .login-pic {
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
  }

  .login-pic {
    position: relative;
    max-height: 100vh;
    overflow: hidden;

    img {
      object-position: center;
      object-fit: cover;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .login-form {
    flex-direction: column;
    margin: 0 auto;

    .beacon-title {
      margin-bottom: 20px;
      svg {
        width: 337px;
        height: 42px;
        path {
          fill: var(--navbar-color);
        }
      }
    }

    form {
      .form-row {
        min-height: auto;
        margin-bottom: 20px;

        label.form-input {
          margin-bottom: 0px;

          input[type="text"],
          input[type="password"] {
            padding: 13px;
            border-radius: 4px;
            border: 1px solid rgb(190, 191, 199);
          }
        }
      }
    }

    .form-check {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: var(--font-color);
      align-items: center;
      margin-bottom: 35px;
      margin-top: 50px;

      input[type="checkbox"] {
        width: 20px;
        height: 15px;
        transform: translate(-2px, 2px);
      }

      .forgot a {
        color: var(--font-color);
        cursor: pointer;
        text-decoration: none;
      }

      .remember {
        cursor: pointer;
        margin-left: 5px;
        margin-bottom: 3px;
      }
    }

    button.primary.login {
      width: 100%;
      background-color: var(--login-button-color);
      border-radius: 5px;
      border-color: none;
      margin-top: var(--base-space-2x);
    }

    .form-notice {
      color: var(--font-color);
      margin-top: 20px;
      font-size: 12px;

      .ssl-pic {
        border-radius: 5px;
        border: 1px #e6e6e6;
        margin: 60px 0 15px;
      }

      p {
        line-height: 26px;
        &.terms-of-use {
          margin-top: 10px;
        }
      }
    }
  }

  .login-pic {
    max-height: 100vh;
    min-height: 100vh;
    max-width: 100vw;
  }

  input[type="text"],
  input[type="password"] {
    padding-left: 5px;
    font-size: 14px;
  }

  .customer-app-notice {
    h1 {
      text-align: center;
    }
  }
}

.page-login {
  &.for-admins {
    .login-form {
      width: 320px;
      padding: 0;
    }
  }

  &.for-customers {
    background-color: #f0f0f0;
    h1 {
      text-align: center;
    }
    .login-form {
      height: 80vh;
      min-height: 600px;
      margin-top: 50px;
      border-radius: 3px;
      width: 100%;
      padding: 0px 60px;
      max-width: 600px;
      .beacon-title svg {
        width: 100%;
        height: 100%;
        max-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .page-login.for-customers {
    .login-form {
      margin-top: 0px;
    }
  }
}

@media screen and (max-width: 960px) {
  .page-login.for-admins {
    .login-form {
      width: 100%;
      max-width: 560px;
      padding: 0 40px;
    }
    .login-pic {
      display: none;
    }
  }
}

@media screen and (max-width: 400px) {
  .page-login.for-admins {
    .login-form {
      .beacon-title svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
