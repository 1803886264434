.age-chart,
.gender-chart {
  .pie-chart {
    margin: auto;
  }

  .recharts-wrapper {
    margin: auto;
  }

  .pie-stats {
    display: flex;
    justify-content: space-around;
    padding: 50px 10px 0px;
    font-size: 13px;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 10px;
    }

    svg {
      margin-top: 1px;
      margin-right: 5px;
    }

    p {
      opacity: 0.5;
    }
  }
}
