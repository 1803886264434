.analytics {
  .ui--card {
    padding: 10px;
    flex: 1 1 200px;
    .content {
      min-height: 110px;
    }
  }
  .notifications {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .notifications-card {
      flex: 1 1 400px;
      table.top-notifications-table {
        td:nth-child(1) {
          cursor: pointer;
        }
      }
    }
  }

  .top-five-lists {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .top-beacons-and-heatmap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .top-beacons {
      flex: 1 1 0;
    }

    .heatmap-card {
      flex: 2 2 0;

      .title-and-map {
        width: 60%;
      }

      .legend-and-stats {
        width: 40%;
      }

      .content {
        padding-left: 8px;
        padding-right: 8px;
      }

      .world-map {
        height: 450px;
        width: 100%;
        .rsm-geography {
          outline: none;
          fill: var(--map-country-color);
        }
      }
    }
  }
}
