.__react_component_tooltip.show {
  opacity: 1 !important;
}

.__react_component_tooltip::after {
  content: none !important;
}

.__react_component_tooltip {
  .tooltip-card {
    max-width: 350px;
    background-color: var(--card-background-color);
    box-shadow: var(--interactive-surface-box-shadow);
    border-radius: 4px;
    overflow: visible;
    height: 100%;
    padding: var(--base-space) var(--base-space-2x);
    line-height: 2;
    font-size: 14px;
    .tooltip-title {
      font-weight: bold !important;
    }
    ul {
      margin-left: 15px;
    }
  }

  .tooltip-image-card {
    display: flex;
    flex-direction: row;
    max-width: 550px;
    max-height: 400px;
    background-color: var(--card-background-color);
    box-shadow: var(--interactive-surface-box-shadow);
    border-radius: 4px;
    overflow: visible;
    height: 100%;
    padding: var(--base-space) var(--base-space-2x);
    line-height: 2;
    font-size: 14px;
    .tooltip-title {
      font-weight: bold !important;
    }
    .tooltip-status {
      font-weight: bold !important;
      color: #4ad991;
    }
    .tooltip-content {
      padding: 20px;
    }
    .figure {
      position: relative;
      height: 370px;
      width: 200px;

      .frame {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        inset: 0;
        padding: 13%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        strong {
          font-size: 10px;
          color: white;
        }
        p {
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 7px;
          color: white;
        }
      }

      .image {
        position: absolute;
        inset: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
