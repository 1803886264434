.deep-link-form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  width: 600px;

  .key-value-inputs {
    flex: 1 1 200px;
    .form-input:nth-child(1) {
      flex: 1 1;
    }
    .form-input:nth-child(2) {
      flex: 2 2;
    }
  }

  .item-row {
    flex-wrap: wrap;
    align-items: center;

    .editor {
      flex: 0 0 100%;

      h2 {
        color: var(--font-color-light);
        font-size: 12px;
        margin-bottom: 10px;
        padding-left: 10px;
      }

      .input-row {
        display: flex;
        margin-bottom: 10px;
        .prefix {
          line-height: 40px;
          margin-right: 10px;
          color: var(--font-color);
        }
        .form-input {
          flex: 1 1 auto;
          margin-right: 10px;
        }
      }

      & > div {
        flex: 1 1 100%;
      }
    }

    .row-buttons {
      display: flex;
      button {
        padding: 4px 8px;
        margin-right: 10px;
        font-weight: normal;
        font-size: 12px;
      }
      .delete {
        width: 55px;
        color: white;
        background-color: #ed585e;
        border-color: #ed585e;
        &:hover {
          background-color: orangered;
        }
      }
    }
  }

  .condition {
    width: fit-content;
    font-weight: normal;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;

    .cancel {
      margin-right: 4%;
      width: 18%;
    }
    .save {
      width: 18%;
    }
  }
}

@media screen and (max-width: 960px) {
  .deep-link-form {
    width: 100%;

    .buttons {
      .cancel {
        width: 30%;
      }
      .save {
        width: 40%;
      }
    }
  }
}
