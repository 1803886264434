.relative-span-range {
  .relative-time {
    .amount {
      width: 30px;
      min-width: 30px;

      &.reduced {
        margin-left: -5px;
      }

      .error {
        margin: 3px 0px -8px -70px;
      }
    }

    .time-unit {
      max-width: 100px;
    }

    .range-time-unit {
      margin-left: -5px;
      min-width: 120px;
    }

    .between-dash {
      margin: 10px 1px 0 -9px;
    }
  }

  .fixed-date {
    // TODO: fix button CSS from AudienceForm.scss applying to date picker buttons, below are overrides
    button.react-datepicker__navigation {
      width: 20px !important;
      background-color: transparent !important;
      border: none !important;
    }
  }
}
