.app-transaction-users-table table {
  td:nth-child(1) a {
    display: flex;
    align-items: center;
    text-decoration: none;
    .name {
      text-decoration: underline;
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    font-weight: bold;
    width: 35%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 35%;
    text-align: left;
  }

  th:nth-child(3),
  td:nth-child(3) {
    padding-right: 10%;
    text-align: right;
    width: 30%;
  }
}
