body.hide-scrollbars {
  overflow: hidden;
}

.margin-bottom-1x {
  margin-bottom: var(--base-space);
}

.margin-bottom-2x {
  margin-bottom: var(--base-space-2x);
}

.body-color {
  color: var(--font-color);
}

.text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 960px) {
  .small-text-align-center {
    text-align: center;
  }

  .small-overflow-hidden {
    overflow: hidden;
  }
}

@media screen and (prefers-color-scheme: dark) {
  .invert-on-dark-mode {
    filter: invert(0.9);
  }

  .reduce-contrast-on-dark-mode {
    filter: contrast(95%) sepia(15%) brightness(95%);
  }
}
