.poc-user-management {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
    padding: 20px 0;

    .header-title {
      margin-top: 15px;
      h1 {
        font-weight: bolder;
      }
    }
  }

  .name {
    font-weight: bold;
    font-size: 13px;
  }

  .table {
    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4) {
      text-align: left;
      width: 25%;
    }
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 150px;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 55px;
    }
  }
}

@media screen and (max-width: 960px) {
  .dashboard {
    margin: auto;
    .header {
      .header-title {
        width: 45%;
        text-align: center;
        margin: auto 0;
        float: left;
      }
    }
  }
}
