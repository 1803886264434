.main-menu {
  ul {
    display: flex;

    li {
      list-style: none;
      padding: var(--base-space);
      margin-right: var(--base-space-2x);
      line-height: 100%;

      &:nth-child(1) {
        svg {
          margin-right: 3px;
        }
      }
      &:nth-child(3) {
        svg {
          margin-right: 8px;
        }
      }

      a {
        font: var(--font);
        font-size: 12px;
        color: var(--font-color-inverse);
        display: block;
        font-weight: normal;
        text-decoration: none;
        height: 16px;
        svg {
          margin-bottom: -4px;
          path {
            fill: var(--font-color-inverse);
          }
        }
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
