ul.pagination {
  display: inline-block;

  li {
    margin: 0 3px;
    display: inline-block;
    text-align: center;

    &.selected button {
      color: white;
      background-color: var(--button-background);
      border: solid 1px var(--button-background);
    }

    &.disabled button,
    &.disabled button:hover {
      cursor: default;
      color: var(--button-background);
      border: solid 1px var(--border-color-transparent);
      background-color: var(--input-background-color);
      svg path {
        fill: var(--button-background-a05);
      }
    }

    button {
      cursor: pointer;
      color: var(--button-background);
      border: solid 1px var(--border-color-transparent);
      border-radius: 4px;
      background-color: var(--input-background-color);
      font-weight: 400;
      font-size: 12px;
      width: 30px;
      height: 30px;
      padding: 0px;
      box-sizing: border-box;
      user-select: none;

      svg {
        height: 12px;
        position: relative;
        top: 1px;
        path {
          fill: var(--button-background);
        }
      }

      &:focus {
        border: solid 1px var(--border-color-transparent);
        outline: none;
      }

      &:hover {
        color: white;
        background-color: var(--button-background);
        border: solid 1px var(--button-background);
        svg path {
          fill: white;
        }
      }
    }
  }

  .jump-text-before {
    padding-left: 5px;
  }

  .jump-text-after {
    padding-right: 5px;
  }

  .jump {
    .jump-input {
      input[type="number"] {
        height: 28px;
        width: 28px;
        border-radius: 2px;
        text-align: center;
        padding: 0px;
      }
    }
  }
}
