.favorite-stores {
  table {
    th:nth-child(1) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: left;
      font-weight: bold;
    }

    th:nth-child(2),
    td:nth-child(2) {
      max-width: 160px;
      min-width: 100px;
      width: 100px;
    }
    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }

  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
