.paginated-cards {
  margin: 20px 0 0 0;
  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .ui--card {
      width: 33.333%;
      height: 530px;
      margin: 0px;
      padding: 10px;
    }
  }

  footer {
    text-align: right;
    margin: 40px 5px 10px 5px;
  }
}
