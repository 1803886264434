.message-form {
  width: 1077px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .header {
    p.description {
      margin-top: 5px;
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
    }
  }

  .form-row {
    min-height: 37px;
    margin-bottom: 16px;
    .form-input {
      margin-bottom: 0px;
      &.language-picker {
        margin-top: 26px;
        width: 320px;
      }
    }
  }

  .not-schedule {
    font-size: 11px;
    font-weight: normal;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;

    .audience-total {
      margin-bottom: 5px;
    }
    .audience-display {
      overflow: hidden;
      max-height: 58px;
    }
    .warning {
      margin-bottom: 15px;
      font-size: 13px;
      color: var(--highlight-orange);
    }

    .call-to-action {
      .form-input:nth-child(1) {
        flex: 1 1;
      }
      .form-input:nth-child(2) {
        flex: 2 2;
      }
    }

    .deeplink-redeem-notify {
      .form-input {
        flex: 1 1;
      }
    }

    .tokens {
      .lead-text {
        margin-right: 7px;
        margin-top: 12px;
        font-size: 13px;
      }
      .token {
        margin-bottom: 10px;
      }
      .token:hover {
        cursor: pointer;
      }
    }
    .validity-pane {
      width: 183px;
    }

    .top-control {
      height: 45px;
    }

    .left-sub-pane {
      background: #f1f1f5;
      padding: 13px;
      border-radius: 4px;
    }

    .toggle {
      cursor: pointer;
      display: inline-block;
    }

    .toggle-switch {
      display: inline-block;
      background: #ccc;
      border-radius: 16px;
      width: 58px;
      height: 32px;
      position: relative;
      vertical-align: middle;
      transition: background 0.25s;
    }
    .toggle-switch:before,
    .toggle-switch:after {
      content: "";
    }
    .toggle-switch:before {
      display: block;
      background: linear-gradient(to bottom, #fff 0%, #eee 100%);
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
      width: 24px;
      height: 24px;
      position: absolute;
      top: 4px;
      left: 4px;
      transition: left 0.25s;
    }
    .toggle:hover .toggle-switch:before {
      background: linear-gradient(to bottom, #fff 0%, #fff 100%);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
    .toggle-checkbox:checked ~ .toggle-switch {
      background: #bada55;
    }
    .toggle-checkbox:checked ~ .toggle-switch:before {
      left: 30px;
    }

    .toggle-checkbox {
      position: absolute;
      visibility: hidden;
    }

    .toggle-label {
      margin-left: 5px;
      position: relative;
      color: #646464;
      top: 2px;
    }

    .schedule {
      .schedule-button {
        width: 244px;
        padding-left: 10px;
        padding-right: 13px;
        margin: 0 !important;

        svg {
          top: 3px;
          left: -5px;
          height: 1.2em;
          position: relative;
        }
      }

      .schedule-text {
        align-self: center;
        color: var(--font-color);
        font-size: 0.8em;
        font-weight: bold;
        width: 100%;
        margin-left: 7px;

        .error {
          display: block;
          color: var(--error-color);
          font-size: 0.8em;
          font-weight: lighter;
        }
      }
    }
    .rich-text.form-input {
      background-color: white;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 1 330px;
    margin-left: 24px;
    margin-right: 24px;
    .message-image {
      flex: 0 0 715px;
      margin-bottom: 40px;
      .message-content {
        padding: 14px 0px;
        .title {
          font-size: 14px;
        }
        .text {
          font-size: 10px;
        }
      }
    }
    .image-upload-button {
      margin-top: -40px;
      z-index: 10;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;

    .cancel {
      margin-right: 10px;
    }
    .save {
      margin-right: 0px;
    }
  }

  .action-button-row {
    display: block !important;

    .button-pane {
      float: right;

      .mr-0 {
        margin-right: 0px;
      }
    }
  }
}
