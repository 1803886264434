.stats-card-with-trend {
  font-size: 13px;

  &.clickable {
    cursor: pointer;
  }

  .content {
    padding: var(--base-space-2x);
  }

  strong {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
  }

  .card-label {
    font-size: 12px;
    white-space: nowrap;
    font-weight: bold;
    display: block;
    color: var(--beacon-grey);
  }

  div svg {
    margin-bottom: -2px;
  }

  strong {
    font-size: 15px;
  }

  .avgStat {
    color: #a4a6b5;
  }

  .total-and-dots {
    font-size: 20px;
  }
}
