.health-check {
  .featured-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .inner-card {
      flex: 1 1 0;
    }
  }
}
