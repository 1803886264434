.ui--card {
  padding: 10px;

  .content {
    padding: var(--base-space-4x);
    background-color: var(--card-background-color);
    box-shadow: var(--interactive-surface-box-shadow);
    border-radius: 4px;
    overflow: visible;
    height: 100%;

    &[aria-selected="true"] {
      transform: scale(1.01);
      box-shadow: var(--interactive-surface-box-shadow-intense);
    }
  }
}

.ui--card.add-new {
  cursor: pointer;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: 0.2;
    }
  }
}
