.location-overview-beacons {
  td:nth-child(1) {
    font-weight: bold;
  }

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }

  th:nth-child(3),
  td:nth-child(3) {
    text-align: right;
  }

  td:nth-child(2) {
    font-weight: normal;
  }
}
