.customer-app-notice {
  .download-locations {
    margin-top: 20px;
    display: flex;
    a {
      flex: 1 1;
    }
  }
  .google-logo {
    img {
      width: 100%;
    }
  }
  .apple-logo {
    svg {
      width: 100%;
      padding: 0px 23px 3px 23px;
      height: 100%;
    }
  }
}
