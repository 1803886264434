.customer-segment-card {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 380px;
    padding: var(--base-space-2x);

    .details {
      flex: 1 1;
      width: 100%;
      font-size: 13px;
      font-family: Arial;
      h1 {
        font-size: 20px;
        font-weight: bold;
        color: var(--message-title-color);
        margin-top: 16px;
        margin-left: 16px;
        margin-bottom: 16px;
      }
    }

    .buttons {
      flex: 0 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;
      .edit {
        font-weight: bold;
        color: #2580ec;
        font-size: 14px;
      }
      .delete {
        font-weight: bold;
        color: #ed585e;
        font-size: 14px;
      }
      .delete:hover {
        color: orangered;
      }
    }
  }
}
