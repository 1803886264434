/**
* Form section
*/

select {
  outline: none;
}

form .footer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.form-input {
  .hint {
    font-size: 12px;
    display: block;
    color: var(--font-color-light);
    line-height: 15px;
    padding: 0px 10px;
    background-color: white;
    margin-top: 3px;
    max-width: 90%;
  }
  .error {
    font-size: 1em;
    display: block;
    color: var(--error-color);
    white-space: nowrap;
    width: fit-content;
    height: 10px;
    position: relative;
    line-height: 6px;
    left: 9px;
    top: 3px;
    padding: 0px 4px;
    background-color: white;
    margin-top: -11px;
  }
}

form div.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--base-space-2x);
  min-height: 70px;
  &.spaced {
    margin-top: 50px;
  }
  .error {
    max-width: 90%;
    white-space: normal;
    line-height: 12px;
  }
  .form-checkbox,
  .form-input {
    font-size: 14px;
    width: 100%;
    margin-bottom: var(--base-space-4x);
    margin-left: var(--base-space);
    margin-right: var(--base-space);
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  .react-datepicker__tab-loop:last-child {
    margin-left: -8px;
  }
}

button {
  box-sizing: border-box;
  cursor: pointer;
  font: var(--font);
  color: var(--font-color);
  font-size: 16px;
  padding: var(--base-space) var(--base-space-2x);
  background-color: var(--button-background-inverse);
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: bold;
  height: 42px;
  transition: background-color 200ms ease, border-color 200ms ease, opacity 200ms ease;

  &:disabled {
    opacity: 0.75;
    box-shadow: none;
    cursor: default;
  }
  &:focus {
    outline-style: none;
  }
}

button.new,
button.primary {
  font-size: 17px;
  color: var(--button-background-inverse);
  background-color: var(--button-background);
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    &:not(:disabled) {
      background-color: var(--button-background-hover);
    }
  }
  &.delete {
    color: var(--button-background-danger-inverse);
    background-color: var(--button-background-danger);
    &:hover {
      &:not(:disabled) {
        background-color: var(--button-background-danger-hover);
      }
    }
  }
}

button.secondary {
  font-size: 17px;
  color: var(--button-background-light-inverse);
  background-color: var(--button-background-light);
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    &:not(:disabled) {
      background-color: var(--button-background-light-hover);
    }
  }
}

button.new {
  font-weight: normal;
  margin: 0px 10px;
  min-width: 160px;
}

button.save {
  min-width: 100px;
}

button.primary {
  margin-right: 10px;
  text-align: center;

  &:disabled {
    opacity: 0.75;
    box-shadow: none;
    cursor: default;
  }

  &:focus {
    outline-style: none;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--button-background-hover);
    }
  }
}

button.download {
  display: none;
  visibility: hidden;
  margin: 0px 10px;
  background-color: white;
  svg {
    margin-top: 2px;
    path {
      fill: var(--button-background);
    }
  }
  border-radius: 4px;
  background-color: var(--button-background-inverse);
  height: 42px;
}
