.image-upload-button {
  text-align: center;
  &.invalid {
    button {
      color: var(--error-color);
      border: 1px solid var(--error-color);
      background-color: var(--error-background);
      &:hover:not(:disabled) {
        background-color: var(--error-background);
      }
      svg path {
        fill: var(--error-color);
        opacity: 0.5;
      }
    }
  }
  button {
    svg {
      top: 2px;
      width: 1.6em;
      height: 1.2em;
      position: relative;
      path {
        fill: var(--button-background-light-inverse);
        opacity: 1;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
  .instruction {
    color: var(--font-color);
    font-size: 0.8em;
  }
  .error {
    margin-left: 10px;
    color: var(--error-color);
    font-size: 0.8em;
    display: inline;
    left: 0px;
    top: 0px;
    padding: 0px;
  }
}

.image-upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-color: var(--background-color-light);
  height: 100%;

  .dnd-sensor {
    position: absolute;
    inset: 0;
  }

  label {
    display: block;
    text-align: center;
    cursor: pointer;
    padding: 10%;
    font-size: 14px;
    svg {
      width: 100px;
      height: 100px;
      path {
        opacity: 0.4;
        fill: var(--message-text-light);
      }
    }
    span {
      text-align: center;
      color: var(--message-text-light);
      display: block;
    }
  }

  input[type="file"] {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .preview {
    width: 164px;
    height: 104px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    margin: 15px auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;

    i {
      display: block;
      font-size: 50px;
      margin: 35px 0 10px 0;
    }
  }

  &.upload-in-progress .upload-info {
    display: block;
    background: #eef1f7;
    .common-loading {
      margin-top: 200px;
      width: 126px;
      height: 176px;
      .lds-dual-ring::after {
        border-width: 35px;
      }
      strong {
        font-size: 20px;
      }
    }
  }

  &.drag-active {
    box-shadow: 0px 0px 18px 12px var(--focused-highlight);
  }

  &.invalid {
    border: 2px solid var(--error-color);
    background-color: var(--error-background);
    svg path {
      fill: var(--error-color);
    }
    label span {
      color: var(--error-color);
      text-align: center;
    }
  }
}
