.top-products-table,
.all-products-table {
  table {
    th:nth-child(1) {
      padding-left: 10px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      width: 62px;
      min-width: 62px;
      max-width: 62px;
      img {
        height: 50px;
        width: 40px;
        object-fit: cover;
        object-position: center center;
        margin-left: 3px;
      }
    }
    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
      font-weight: bold;
      padding-right: 0px;
    }

    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: right;
      padding-left: 0px;
    }

    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }

  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
