.featured-item-card {
  height: auto !important;

  .content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .figure {
      object-fit: contain;
      width: 100%;
      height: 230px;
      overflow: hidden;
      img {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .description {
    width: 100%;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-around;
    padding: var(--base-space-2x) var(--base-space-4x);

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }

    p {
      margin-top: var(--base-space-2x);
      margin-bottom: var(--base-space-2x);
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
    }

    .update-buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: auto;
      font-weight: bold;
      font-size: 14px;

      button.delete {
        color: #ed585e;
      }

      button.edit {
        color: #2580ec;
      }
    }
  }
}
