.legend-list {
  display: block;
  list-style-type: none;
  margin: 10px 0px 10px 0px;
  padding: 8px;
}

.legend-list .legend-item {
  line-height: 18px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 300;
  list-style-type: none;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.base-symbol {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1em;
  transform: translate(0, 3px);
}

.circle {
  border-radius: 50%;
}
