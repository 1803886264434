@import url("https://fonts.googleapis.com/css?family=Lato:400,600,700&display=swap&subset=latin-ext");

:root {
  --font: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  --modal-title-color: rgb(67, 66, 93);
  --message-title-color: rgb(103, 105, 116);
  --message-text-light: rgb(196, 197, 201);
  --message-text-dark: rgb(173, 174, 180);
  --font-color: rgb(100, 100, 100);
  --font-color-light: rgb(140, 140, 140);
  --font-color-inverse: rgb(255, 255, 255);
  --error-color: rgb(237, 88, 94);
  --error-background: rgba(237, 88, 94, 0.1);

  --button-background: rgb(165, 164, 191);
  --button-background-inverse: rgb(255, 255, 255);
  --button-background-hover: rgb(135, 134, 159);
  --button-background-a09: rgba(165, 164, 191, 0.9);
  --button-background-a05: rgba(165, 164, 191, 0.5);
  --button-background-a01: rgba(165, 164, 191, 0.1);
  --button-background-danger: rgb(237, 88, 94);
  --button-background-danger-hover: rgb(255, 94, 94);
  --button-background-danger-inverse: rgb(255, 255, 255);
  --button-background-light: rgb(240, 242, 248);
  --button-background-light-hover: rgb(220, 222, 228);
  --button-background-light-inverse: rgb(100, 100, 100);
  --link-color: rgb(10, 10, 10);
  --page-max-width: 1360px;

  --background-color: rgb(235, 235, 235);
  --background-color-light: rgb(245, 245, 245);
  --background-color-transparent: rgb(235, 235, 235, 0.5);
  --default-border-color: rgb(221, 221, 221);
  --map-country-color: rgb(227, 230, 237);
  --border-radius: 12px;

  --login-button-color: rgb(74, 217, 145);
  --navbar-color: rgb(77, 79, 92);
  --navbar-height: 70px;
  --navbar-background: rgb(77, 79, 92);
  --base-space: 8px;
  --base-space-2x: 16px;
  --base-space-4x: 32px;
  --base-space-8x: 64px;
  --base-space-negative: -8px;
  --base-space-2x-negative: -16px;
  --base-space-4x-negative: -32px;
  --base-space-8x-negative: -64px;
  --mobile-breakpoint: 640px;

  --overlay-background-color: rgba(255, 255, 255, 0.55);
  --surface-background-color: rgb(255, 255, 255);
  --interactive-surface-background-color: rgb(255, 255, 255);
  --interactive-surface-background-color-hover: rgb(255, 255, 255);
  --interactive-surface-box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
  --interactive-surface-box-shadow-intense: 4px 6px 8px 4px rgba(0, 0, 0, 0.1);

  --border-color: rgb(235, 235, 235);
  --border-color-transparent: rgb(0, 0, 0, 0.1);

  --input-border-color: rgb(190, 191, 199);
  --input-background-color: rgb(255, 255, 255);

  --card-border-radius: 24px;
  --card-background-color: rgb(255, 255, 255);

  --beacon-blue: #8fcae2;
  --beacon-blue-darker: rgb(53, 124, 191);
  --beacon-grey: rgb(77, 79, 92);
  --beacon-grey-a08: transparentize($color: #4d4f5c, $amount: 0.8);

  --highlight-black: black;
  --highlight-lightgreen: #c1ffa2;
  --highlight-green: #4ad991;
  --highlight-purple: #a4a1fc;
  --highlight-lightblue: #8fcae2;
  --highlight-blue: #549dba;
  --highlight-red: #f55e7b;
  --highlight-pink: #be71a3;
  --highlight-orange: #f8b112;
  --highlight-golden: #e2bc8f;
  --highlight-yellow: #ffda82;
  --highlight-white: #e3e3e5;

  --loading-color: rgb(163, 166, 180, 0.267);

  --focused-highlight: rgb(143, 202, 226, 0.3);
}
