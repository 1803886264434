.label-and-campaign {
  margin-left: 13px;

  .label {
    font-weight: bold;
  }
  .campaign {
    font-size: 10px;
  }
  .converting-offer {
    font-size: 10px;
    font-weight: bold;
  }
}
