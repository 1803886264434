.customer-details {
  .label {
    font-weight: bold;
  }

  .container {
    position: relative;
    display: flex;
  }

  .page-header {
    flex: 1;
    padding-left: 10px !important;
  }

  .left {
    flex: 1;
    .ui--card {
      margin: 20px 10px 10px 10px;
    }
  }

  .right {
    flex: 2;
    .ui--card {
      margin: 20px 10px 10px 10px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-between;
    .ui--card {
      flex: 1 1;
    }
  }

  .message-history table {
    td {
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 50px;
      text-align: left;
      text-overflow: ellipsis;
    }
    .message-preview {
      .image {
        border-radius: 4px;
        position: relative;
        margin: -50px 12px 0px 8px;
        top: 25px;
        height: 62px;
        width: 62px;
        object-fit: contain;
      }
      .name {
        font-weight: bold;
      }
    }
  }

  .customer-purchase-history table {
    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      font-weight: bold;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: center;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: center;
    }

    th:nth-child(4),
    td:nth-child(4) {
      text-align: center;
    }
  }

  .basic-info {
    .content {
      min-height: 720px;
    }
    .fields {
      margin-top: 34px;
      border-top: 1px solid var(--default-border-color);
      padding-bottom: 0px;
    }
    .initials,
    .cover {
      background-size: contain;
      background-position: center;
      height: 175px;
      width: 175px;
      line-height: 165px;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      font-size: 100px;
      font-weight: normal;
    }
    .initials {
      background: var(--beacon-blue);
      color: var(--font-color-inverse);
    }
    .name {
      h1 {
        font-weight: bold;
      }
      text-align: center;
      margin-top: 10px;
    }
    .age {
      text-align: center;
      font-weight: normal;
      font-size: 13px;
      margin-top: 4px;
    }
    .email {
      grid-area: span 1 / span 2;
    }
  }

  h1 {
    font-weight: bold;
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 5px 20px 20px 20px;
    .f {
      margin-top: 15px;
    }
    .title {
      font-weight: normal;
      font-size: 12px;
      color: var(--beacon-grey);
      margin-bottom: 4px;
    }
    .value {
      font-weight: bold;
      font-size: 13px;
      color: var(--beacon-grey);
    }
  }
}
