.location-dashboard {
  .map {
    .content {
      padding: 20px;
      .full-world-map {
        height: 600px;
      }
    }
  }

  .stores-and-beacons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .stores {
      flex: 2 2 150px;
    }
    .beacons {
      flex: 1 1 150px;
    }
  }
}
