.dropdown_container {
  position: relative;
  max-width: 200px;

  .option__elem {
    font-weight: normal;
    font-size: 12px;
  }
}

.dropdown_elem {
  border-radius: 4px;
  padding: 12px;
  background-color: var(--navbar-background);
  width: 100%;
  display: flex;
  justify-content: space-around;

  .dropdown_label {
    font-weight: normal;
    font-size: 12px;
    color: white;
  }

  .dropdown_select {
    margin-left: 10px;
    margin-top: -6px;

    svg {
      path {
        fill: white;
      }
    }
  }
}

.option_elem {
  padding: 10px;
  background-color: var(--navbar-background);
}

.option_elem:hover {
  background-color: var(--navbar-background);
}
