.schedule-form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  width: 600px;

  .form-row {
    display: flex;
    justify-content: flex-end;
    padding-left: 70px;
    padding-right: 100px;
    min-height: 50px;
    height: 50px;
    .prefix {
      white-space: nowrap;
      overflow: visible;
      line-height: 40px;
      margin-right: 10px;
      color: var(--font-color);
    }
    .form-input {
      width: 270px;
    }
  }

  .resend {
    &.reduced {
      display: inline-block;
      width: 200px;
    }
  }
  .custom-days {
    &.reduced {
      display: inline-block;
      width: 50px;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;

    .cancel {
      margin-right: 4%;
      width: 18%;
    }
    .save {
      width: 18%;
    }
  }
}

.schedule-header .warning {
  margin-top: 7px;
  font-size: 13px;
  color: var(--highlight-orange);
}

@media screen and (max-width: 960px) {
  .schedule-form {
    width: 100%;

    .buttons {
      .cancel {
        width: 30%;
      }
      .save {
        width: 40%;
      }
    }
  }
}
