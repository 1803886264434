.paginated-table {
  position: relative;
  table.loading {
    opacity: 0.2;
  }

  th.sortable {
    cursor: pointer;
    user-select: none;
  }

  .common-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 40px;
    .select {
      flex: 0 1 100px;
      border-radius: 6px;
      text-align: center;
      height: 40px;
      margin-bottom: -8px;
      .select__control {
        min-height: 10px;
      }
      .select__single-value {
        width: 100%;
        margin-left: 14px;
      }
    }
  }
}
