section header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: var(--beacon-grey);

  h1 {
    font-weight: bold;
    a,
    .link {
      color: var(--beacon-grey);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  select {
    border: none;
    color: var(--beacon-grey);
    font-weight: bold;
    min-width: 120px;
    border-bottom: 2px solid var(--beacon-grey-a08);
    background: none;
  }
}

table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;

  &.with-image {
    td:nth-child(1) {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      overflow: hidden;
      text-overflow: clip;
    }
  }

  td,
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0; // need for ellipsis to work
  }

  th {
    padding: 15px 15px;
    font-size: 11px;
    font-family: Arial;
    text-align: center;
    background-color: #f5f6fa;
    color: #a3a6b4;
  }

  td {
    font-size: 13px;
    padding: 28px 15px;
    color: var(--beacon-grey);
    border-bottom: 1px solid var(--default-border-color);

    a,
    .link {
      color: var(--beacon-grey);
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      font-size: 13px;
    }
  }
}

tr.loading td {
  padding: 40px;
  border-bottom: 0px;
}

footer {
  font-size: 12px;
  a {
    text-decoration: none;
    color: #8fcae2;
  }
}
