label.form-checkbox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 10px;

  .error {
    font-size: 1em;
    display: block;
    color: var(--error-color);
    margin-top: var(--base-space);
    margin-bottom: -26px;
    padding-left: 6px;
    white-space: nowrap;
  }

  .label-text {
    display: flex;
    align-items: center;
    color: var(--font-color);
  }
  input {
    margin-right: 12px;
    width: 1.1em;
    height: 1.1em;
  }
  .error {
    padding-left: 10%;
  }
}
