.analytics-top-row {
  &.featured-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .draggable-card {
      flex: 1 1 0;
    }
  }
}
