.beacon-data-table {
  header {
    h1 {
      font-size: 21px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }
    .description {
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
    }
  }
  table {
    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      width: 25%;
      font-weight: bold;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
      width: 15%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: left;
      width: 25%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 15%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 20%;
    }
  }
}
