.rate-of-occurence {
  .first {
    min-width: 145px;
  }
  .second {
    .input-row {
      justify-content: flex-start !important;
    }
    .amount {
      width: 45px;
      max-width: 45px;

      .error {
        margin: 3px 0px -8px -70px;
      }
    }
    .postfix {
      display: inline;
    }
  }
}
