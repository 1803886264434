.black {
  color: var(--highlight-black);
}
.lightgreen {
  color: var(--highlight-lightgreen);
}
.green {
  color: var(--highlight-green);
}
.purple {
  color: var(--highlight-purple);
}
.lightblue {
  color: var(--highlight-lightblue);
}
.blue {
  color: var(--highlight-blue);
}
.red {
  color: var(--highlight-red);
}
.pink {
  color: var(--highlight-pink);
}
.orange {
  color: var(--highlight-orange);
}
.golden {
  color: var(--highlight-golden);
}
.yellow {
  color: var(--highlight-yellow);
}
