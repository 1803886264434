.message-image {
  @-moz-document url-prefix() {
    .fixed-frame {
      width: 237.5px !important;
      display: absolute;
    }
  }

  .platform-selector {
    margin: 0 0 10px 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 10;

    .ios-select,
    .android-select {
      color: white;
      font-size: 13px;
      text-align: right;
      width: 80px;
      background: #cccccc;
      border-radius: 12px;
      cursor: pointer;

      &.selected {
        background: grey;
      }
    }
    .ios-select {
      margin-right: 10px;
      padding: 1px 15px 3px 0;
      svg {
        margin: 0 2px -5px 0;
      }
    }
    .android-select {
      padding: 2px 10px 2px 0;
      svg {
        margin: 0 3px -2px 0;
      }
    }
  }
  .page-selector {
    height: 13px;
    margin: 0 0 -34px 160px;
    position: relative;
    z-index: 10;

    .notification-page-select,
    .offer-page-select {
      height: 14px;
      width: 14px;
      background: #cccccc;
      float: left;
      margin-right: 5px;
      border-radius: 7px;
      cursor: pointer;

      &.selected {
        background: grey;
      }
    }
  }
  .fixed-aspect {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    height: 100%;

    canvas {
      height: 100%;
      background: transparent;
    }

    .frame {
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      inset: 0;
      padding: 10% 10% 14% 10%;
      box-sizing: border-box;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      .preview {
        z-index: 0;
        position: relative;
        margin: 20% 0% 4% 2%;
        width: 94%;
        height: 100%;
        //border-radius: 5%;
      }
      .frame-img {
        z-index: 1;
        position: absolute;
        inset: 0;
        padding: 10% 10% 14% 10%;
        box-sizing: border-box;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .notification-content {
        opacity: 0.99;
        padding: 13px 7px 17px 7px;
        border-radius: 18px;
        min-height: fit-content;
        width: 89%;
        margin-left: 5%;
        &.ios {
          margin-top: 200px;
          background-color: #d4efeb;
        }
        &.android {
          margin-top: 270px;
          background-color: #faf6f5;
        }
        .title {
          display: block;
          padding: 0px 4px 0px 4px;
          font-size: 9px;
        }
        .push-message {
          margin-left: 7px;
          margin-top: 5px;
          font-size: 9px;
        }
        .app-icon {
          width: 36px;
          height: 36px;
          float: left;
          margin-right: 7px;
          margin-left: 2px;
          background-size: contain;
        }
      }
      .button-bar {
        font-size: 12px;
        text-decoration: underline;
        border-radius: 2px;
        text-align: center;
        color: white;
        padding: 8px 0px 11px 0px;
        background-color: #a6bc19;
        width: 70%;
        margin: auto;
        margin-top: 5%;
        margin-bottom: 15%;
      }
      .title,
      .text,
      .button-bar {
        cursor: default;
      }
    }
  }
  .disclaimer {
    font-size: 12px;
    color: var(--font-color);
    text-align: center;
    margin: -40px 0 0 30px;
    clear: both;
  }
}
