.date-range-form {
  position: relative;
  .selector {
    line-height: 20px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    color: var(--font-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .icon-left {
      margin-right: 10px;
    }
    .value {
      min-width: 80px;
    }
    .icon-right {
      margin-left: 10px;
    }
  }
  .calendar-wrapper {
    z-index: 1;
    position: absolute;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
}
