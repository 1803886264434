.ReactModal__Overlay {
  top: var(--navbar-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9998;
}

.ReactModal__Content {
  margin-top: 5%;
  margin-bottom: 30%;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 5px 16px -2px #00000030;

  &.wide-modal {
    width: 80vw;
    outline: none;

    .ui--card {
      padding: 0;
    }
  }

  .header {
    h1.title {
      color: var(--modal-title-color);
      font-size: 20px;
      font-weight: bold;
    }
    h2 {
      color: var(--modal-title-color);
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0;
    }
    p.description {
      margin-top: 20px;
      color: var(--font-color);
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 760px) {
  .ReactModal__Content {
    margin-top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
    box-shadow: none;
  }
}
