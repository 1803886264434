.rich-text.form-input {
  .quill {
    outline: 0;
    height: 300px;
    border-radius: 4px;
    border: 1px solid var(--input-border-color);
    .ql-toolbar {
      border: none;
      padding: 4px;
      button,
      .ql-picker-label,
      .ql-picker-item {
        background-color: var(--button-background-light);
        height: 40px;
        width: 40px;
        padding: 12px;
        margin: 3px;
        svg path {
          stroke: var(--button-background-light-inverse);
        }
      }
    }

    .ql-container {
      font: var(--font);
      color: var(--font-color);
      border: none;
      overflow-y: auto;
      max-height: 240px;
      margin-right: 4px;
      .ql-editor {
        &.ql-blank::before {
          color: var(--font-color);
          font-style: normal;
        }
      }
    }

    #emoji-palette {
      span.bem.ap {
        margin: 2px;
      }
    }
  }

  .error {
    font-size: 1em;
    display: block;
    height: 10px;
    color: var(--error-color);
    white-space: nowrap;
    width: fit-content;
    position: relative;
    line-height: 6px;
    left: 9px;
    top: 5px;
    padding: 0px 4px;
    background-color: white;
    margin-top: -10px;
  }

  &.invalid .quill {
    border: 1px solid var(--error-color);
  }

  &.focused .quill {
    border-color: var(--beacon-blue);
  }
}
