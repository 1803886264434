.revenue-statistics {
  .analytics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ui--card {
      flex: 1 0 360px;
    }
  }
  .trends {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    .shopper-trends.ui--card {
      flex: 2 2 100px;
    }
    .ui--card {
      flex: 1 1 50px;
    }
  }
}
