.audience-form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  padding-top: 30px;
  width: 600px;

  .debug {
    position: fixed;
    background: white;
    top: 100px;
    left: 40px;
    border: 1px solid;
    padding: 1em;
    width: 23em;
  }

  label {
    margin-bottom: var(--base-space-4x);
  }

  .rule {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .select-audience {
      flex: 1 1 200px;
    }

    .editor {
      margin-top: 10px;
      flex: 0 0 100%;
      padding-right: 95px;

      h2 {
        color: var(--font-color-light);
        font-size: 12px;
        margin-bottom: 10px;
        padding-left: 10px;
      }

      .input-row {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        .prefix,
        .postfix {
          white-space: nowrap;
          overflow: visible;
          line-height: 40px;
          margin-right: 10px;
          color: var(--font-color);
        }
        .postfix {
          margin-left: -5px;
        }
        .form-input {
          flex: 1 1 auto;
          margin-right: 10px;
        }
        &.single {
          .select:last-child {
            flex: 0 0 300px;
          }
        }
        &.double {
          .first {
            flex: 0 0 135px;
            &.reduced {
              flex: 0 0 103px;
            }
          }
          .second {
            flex: 0 0 165px;
          }
          .select:last-child {
            &.select--is-disabled {
              display: none;
            }
          }
        }
        &.num-or-percent {
          .form-input {
            flex: 0 0 100px;
            &.select {
              flex: 0 0 180px;
            }
          }
        }
      }

      & > div {
        flex: 1 1 100%;
      }
    }

    .delete {
      padding: 4px 8px;
      width: 55px;
      margin-left: 50px;
      font-weight: normal;
      background-color: #ed585e;
      font-size: 12px;
      border-color: #ed585e;
    }

    .delete:hover {
      background-color: orangered;
    }

    .form-input {
      margin-bottom: 0px;
      &.description {
        max-width: 300px;
      }
    }
  }

  .condition,
  .and-or button,
  .rule button {
    font-size: 13px;
    text-align: center;
    font-weight: normal;
    padding: 7px 0px;
    width: 41px;
    border: solid 1px #e8e9ec;
    border-radius: 5px;
    color: white;
    background-color: var(--button-background);
  }

  .and-or {
    width: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
    .inactive {
      margin-left: 3%;
      color: var(--button-background);
      background-color: #ffff;
    }
  }

  .condition {
    margin-top: 20px;
    width: fit-content;
    padding: 10px;
    font-weight: normal;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;

    .cancel {
      margin-right: 4%;
      width: 18%;
    }
    .save {
      width: 18%;
    }
  }
}

.audience-display {
  li {
    flex: 1 1;
    font-size: 13px;
    border-radius: 3px;
    color: var(--button-background-light-inverse);
    background: var(--button-background-light);
    margin: 2px;
    padding: 0px 8px;
    line-height: 21px;
    border-radius: 3px;
    display: inline-block;
    &.or {
      color: var(--button-background-inverse);
      background: var(--button-background);
      font-size: 12px;
      margin-left: 11px;
      margin-right: 11px;
      padding: 0px 7px 0px 6px;
      line-height: 28px;
    }
  }
}
.audience-total {
  margin-left: 10px;
  color: var(--font-color);
  font-size: 0.8em;
}

@media screen and (max-width: 960px) {
  .audience-form {
    width: 100%;

    .buttons {
      .cancel {
        width: 30%;
      }
      .save {
        width: 40%;
      }
    }
  }
}
