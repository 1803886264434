.push-notifications-table {
  table {
    .clickable {
      cursor: pointer;
      text-decoration: underline;
    }

    th:nth-child(1) {
      text-align: left;
      width: 30%;
    }
    td:nth-child(1) {
      padding: 0px 15px;
      text-align: left;
      width: 30%;
      span {
        display: flex;
        align-items: center;
        img {
          height: 50px;
          width: 40px;
          object-fit: cover;
          object-position: center center;
          margin-right: 20px;
          margin-left: 3px;
        }
      }
    }
    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }

  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
