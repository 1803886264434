.tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 2px solid var(--border-color);
    padding: 0;
  }

  &__tab {
    line-height: 30px;
    position: relative;
    display: inline-block;
    border: 2px solid transparent;
    border-bottom: none;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    bottom: -2px;
    margin-top: -2px;

    &:first-child {
      border-top-left-radius: 0px;
      border-left: 0px;
    }

    &--selected {
      background: #fff;
      border-color: var(--border-color);
      color: var(--font-color);
      border-radius: 5px 5px 0 0;
      padding: 6px 12px;
      border-bottom: 2px solid white;
    }

    &--disabled {
      color: var(--font-color-light);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
