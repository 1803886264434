.customer-tracking {
  min-height: 170px;

  h2 {
    color: var(--font-color-light);
    font-size: 18px;
  }

  .stats {
    display: flex;
    flex-direction: row;
    .title {
      font-weight: bold;
    }
    .device-panel {
      word-wrap: break-word;

      &.inactive {
        color: #cccccc;
      }
      .header {
        font-size: 30px;
        padding: 4px 0px;
        display: flex;
        button.delete,
        button.diagnostics {
          width: 100px;
          height: 33px;
          font-size: 10px;
          padding: 0px 2px;
          text-align: center;
          margin: 5px 10px;
        }
      }
      strong {
        display: inline-block;
        width: 130px;
      }
      margin-bottom: 12px;
    }
    .message-panel strong {
      display: inline-block;
      width: 100px;
    }
    .primary {
      margin-bottom: 12px;
      text-align: left;
    }
    pre {
      margin: 6px 0px;
      font-size: 0.8em;
    }
    ul {
      flex: 1 1;
      margin-top: 12px;
      li {
        display: block;
        margin-bottom: 4px;
        i {
          display: block;
          font-size: 12px;
          padding-left: 60px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
