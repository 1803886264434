.location-dashboard {
  .filter {
    width: 10em;
  }

  .stores {
    td,
    th {
      text-align: left;
      white-space: nowrap;
    }

    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(2),
    td:nth-child(2) {
      width: 20%;
    }

    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6) {
      text-align: center;
    }

    td:nth-child(1),
    td:nth-child(5) {
      font-weight: bold;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 15%;
    }
  }
}
