.search {
  margin-top: 2px;
  margin-bottom: -2px;
  position: relative;
  width: 100%;
  text-align: right;
  svg.spy {
    top: 3px;
    position: relative;
  }
  svg.clear {
    transition: opacity ease-in-out 700ms;
    opacity: 0;
    cursor: pointer;
    top: 12px;
    right: 11px;
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    path {
      fill: var(--font-color-inverse);
    }
    position: absolute;
  }

  &__disabled.search {
    opacity: 0.5;
    svg.clear {
      cursor: default;
    }
  }

  &__open.search {
    input {
      border-color: white;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  &__with-value.search {
    svg.clear {
      opacity: 1;
    }
  }

  input {
    outline: none;
    border-radius: 4px;
    border: 1px solid transparent;
    height: 38px;
    font: var(--font);
    font-size: 12px;
    color: var(--font-color-inverse);
    background-color: transparent;
    padding: 2px 0px 0px 30px;
    margin-left: -24px;
    width: 0%;
    transition: width ease-in-out 300ms, background-color ease-in-out 500ms, border-color ease-in-out 500ms;
  }
}
