.location-picker {
  border-radius: 4px;
  width: 100%;
  height: 300px;

  .marker svg {
    position: relative;
    top: -24px;
    path {
      fill: var(--highlight-green);
    }
  }
}
