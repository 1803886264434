.sidebar-squeeze {
  &.page-container {
    transition: padding-left ease 200ms;
  }
  &.page-container.sidebar-open {
    padding-left: 270px;
  }
}

.sidebar-push {
  &.page-container {
    .common-header,
    .page-content {
      transition: transform ease 200ms;
    }
  }
  &.page-container.sidebar-open {
    .common-header,
    .page-content {
      transform: translate(270px, 0px);
    }
  }
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.2;
  z-index: 9998;
}

.sidebar {
  font-size: 13px;
  width: 290px;
  height: 100vh;
  background-color: #43425d;
  overflow: hidden auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  transition: left ease 200ms;

  .logo {
    margin: auto 25px;
    height: var(--navbar-height);
    display: flex;
    h1 {
      margin: auto;
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  &.open {
    left: 0px;
  }

  &.closed {
    left: -300px;
  }

  h1 {
    color: white;
  }

  ul {
    margin: auto;
    list-style-type: none;

    li.power-menu-item {
      text-decoration: none;
      .power-menu-link {
        opacity: 0.9;
        text-decoration: none;
        margin: auto;
        line-height: 28px;
        color: #ffffff;
        width: 100%;
        display: flex;
        padding: var(--base-space) 24px;

        .icon svg {
          width: 18px;
          height: 18px;
          margin-top: 5px;
          margin-right: 8px;
          path {
            fill: white;
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &.active {
          opacity: 1;
          .icon svg path {
            fill: var(--beacon-blue);
          }
        }

        &.clickable {
          cursor: pointer;
        }
      }

      &:hover .active-group,
      .active-group.visible {
        height: auto;
        padding-top: var(--base-space);
        padding-bottom: var(--base-space);
      }

      .active-group {
        overflow: hidden;
        height: 0;
        padding: 0;
        // transition: all 1000ms ease-in-out;

        margin: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-left: 5px solid var(--beacon-blue);
        box-shadow: 0px 17px 9px -21px black inset;

        li.power-menu-item .power-menu-link {
          opacity: 0.7;
          padding: 11px 20px;
          line-height: 17px;
          .icon svg {
            margin-top: 0px;
            path {
              fill: rgb(162, 162, 173);
              opacity: 1;
            }
          }

          &.active {
            opacity: 1;
            .icon svg path {
              fill: var(--beacon-blue);
            }
          }
        }
      }
    }
  }
}
