.beacons-table {
  table {
    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      padding-right: 0px;
    }

    td:nth-child(1) {
      font-weight: bold;
      padding-right: 0px;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: right;
      padding-left: 0px;
    }

    footer {
      margin-top: 15px;
      flex-direction: row;
    }
  }

  .show-all {
    color: #9cd0e5;
    cursor: pointer;
  }
}
