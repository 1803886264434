.floorplan-overview {
  .floorplan-upload {
    padding: 10px;
    margin-bottom: 10px;
  }
  svg.add {
    cursor: pointer;
    height: 15px;
    width: 15px;
    vertical-align: bottom;
    margin: 16px 0px 14px 10px;
    path {
      fill: var(--font-color);
    }
  }

  .tabs__tab-panel {
    position: relative;
    .toolbar {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 7000;
      button {
        padding: 2px;
        font-size: 12px;
        margin-bottom: 10px;
        margin-right: 0px;
        width: 100px;
        height: 34px;
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .tabs__tab {
    background-color: white;

    &--selected {
      span {
        text-decoration: underline;
      }
      &.empty {
        background-color: var(--background-color-light);
        border-bottom-color: var(--background-color-light);
      }
    }
    .editable-title {
      position: relative;
      font: var(--font);
      color: var(--font-color);
      font-size: 14px;
      font-weight: bold;
      &.editing {
        color: transparent;
      }
      input {
        &::placeholder {
          font-style: italic;
        }
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        font: var(--font);
        color: var(--font-color);
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        border: none;
        outline: none;
        padding: 0px;
      }
    }
  }

  .layout-uploader {
    height: 600px;
  }

  .map-with-beacons {
    width: 100%;
    height: 600px;
    padding: 20px;
    position: relative;

    .image-upload {
      background-color: transparent;
      label {
        svg {
          width: 130px;
          height: 130px;
        }
        span {
          font-size: 12px;
          margin-top: 10px;
          font-weight: bold;
          color: var(--font-color-light);
        }
      }
      &.upload-in-progress .upload-info {
        background-color: white;
      }
    }

    .beacon-popup {
      position: absolute;
      z-index: 7000;
      width: 400px;
      padding: var(--base-space-4x);
      background-color: var(--card-background-color);
      box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-top: -28px;
      margin-left: 55px;

      &::before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        top: 41px;
        background-color: white;
        position: absolute;
        transform: rotate(45deg);
        left: -6px;
      }
    }

    .map-with-markers {
      height: 100%;
      .markers {
        cursor: pointer;
      }
    }

    .image-upload {
      .common-loading {
        margin-top: 180px;
        width: 200px;
        height: 250px;
        strong {
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
}
