.date-picker {
  padding: 0;
  border: 0;

  .date-picker-label {
    line-height: 22px;
  }

  .input-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: -22px;
    margin-top: auto;
    margin-bottom: auto;
    path {
      fill: rgb(165, 164, 191);
      opacity: 1;
    }
  }

  .error {
    display: block;
    color: var(--error-color);
    margin-top: 4px;
    margin-bottom: -26px;
    padding-left: 6px;
  }

  .react-datepicker {
    &__input-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      height: 40px;
      width: 100%;

      input {
        font: var(--font);
        color: var(--font-color);
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        width: 100%;
        padding: var(--base-space) 2px var(--base-space) 12px;
        &:focus {
          outline: 0;
          border-width: 1px;
          border-color: var(--beacon-blue);
        }
        &.invalid {
          border: 1px solid var(--error-color);
        }
      }
    }
  }
}
