.products {
  .category-filter {
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-card {
    width: 20%;
    height: auto;
  }

  &.columns-2 .cards .ui--card {
    width: 50%;
  }

  &.columns-3 .cards .ui--card {
    width: 33%;
  }

  &.columns-4 .cards .ui--card {
    width: 25%;
  }
}
