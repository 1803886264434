@media screen and (max-width: 1200px) {
  .campaign-dashboard {
    .cards {
      .ui--card {
        width: 47%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .campaign-dashboard {
    margin: auto;
    .cards {
      .ui--card {
        width: 100%;
        min-height: 25vh;
      }
    }
  }
}
