body {
  overflow-x: hidden;
  width: 100vw;
}

.beaconsmind {
  .Toastify {
    &__toast-container {
      width: 50%;
      top: 85px;
    }
    &__close-button {
      height: auto;
    }
  }

  .page-container {
    background-color: #f9f9f9;
    min-height: 100vh;

    .content-container {
      color: var(--beacon-grey);
    }

    section.page-header {
      display: flex;
      justify-content: space-between;
      margin: 0px 10px;
      padding: 20px 0;
      .header-and-info {
        margin-top: 8px;
        h1 {
          font-weight: bolder;
        }
        h2 {
          margin-top: 3px;
          font-size: 12px;
        }
      }
      .toolbar {
        margin: 5px 0px 10px 0;
        display: flex;
        justify-content: space-between;
        .filter {
          margin: 0px 10px;
        }
      }
    }
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

span.highlighted {
  span {
    background-color: rgba(53, 124, 191, 0.3);
    color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 4px 0px rgba(53, 124, 191, 0.7);
    border-radius: 4px;
  }
}

span.last-activity {
  color: var(--font-color-inverse);
  background-color: var(--highlight-lightblue);
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: bold;
  &.top-half {
    padding-bottom: 3px;
  }
  &.bottom-half {
    padding-top: 3px;
  }
  &.lightgreen {
    background-color: var(--highlight-lightgreen);
  }
  &.green {
    background-color: var(--highlight-green);
  }
  &.purple {
    background-color: var(--highlight-purple);
  }
  &.lightblue {
    background-color: var(--highlight-lightblue);
  }
  &.blue {
    background-color: var(--highlight-blue);
  }
  &.red {
    background-color: var(--highlight-red);
  }
  &.pink {
    background-color: var(--highlight-pink);
  }
  &.orange {
    background-color: var(--highlight-orange);
  }
  &.yellow {
    background-color: var(--highlight-yellow);
  }
}

span.store-status {
  color: var(--font-color-inverse);
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
  &.open,
  &.active {
    background-color: var(--highlight-green);
  }
  &.closed,
  &.deactivated {
    background-color: var(--highlight-red);
  }
}

button.for-developers {
  color: var(--highlight-red);
}
