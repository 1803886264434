.top-countries table {
  th:nth-child(1) {
    text-align: left;
  }

  td:nth-child(1) {
    text-align: left;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: bold;
      svg {
        margin-left: 3px;
        margin-right: 15px;
        border-radius: 38px;
        width: 38px;
        height: 38px;
      }
    }
  }

  th:nth-child(2),
  td:nth-child(2) {
    max-width: 160px;
    min-width: 100px;
    width: 100px;
  }
}
