.app-touch-table {
  th:nth-child(1),
  td:nth-child(1) {
    min-width: 65px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 30%;
  }

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
    font-weight: bold;
  }
}
