label.form-input {
  display: flex;
  flex-wrap: wrap;

  .label-text {
    display: block;
    margin-bottom: var(--base-space);

    &.required {
      &::after {
        color: var(--beacon-blue);
      }
    }
  }

  textarea {
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }

  input {
    height: 40px;
  }

  textarea,
  input {
    color: var(--font-color);
    font: var(--font);
    width: 100%;

    padding: 10px 0px 10px 12px;
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;

    &:active,
    &:focus {
      outline: 0;
      border-width: 1px;
      border-color: var(--beacon-blue);
    }

    &.invalid {
      border: 1px solid var(--error-color);
    }
  }

  // hide arrows in number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .input-icon {
    width: 16px;
    height: 16px;
    margin-left: -16px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
