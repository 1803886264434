.debug-page {
  form {
    margin-top: 40px;
    width: 100%;
    .form-row {
      .example-description {
        text-align: right;
        display: flex;
        flex-direction: column;
        .form-checkbox {
          align-items: flex-end;
          font-size: 10px;
          width: auto;
          .label-text {
            flex-direction: row-reverse;
          }
        }
      }

      .form-label {
        text-align: right;
        width: 240px;
        height: 40px;
        line-height: 40px;
        display: block;
      }
      .react-select,
      .filter,
      .select {
        width: 50rem;
        margin-left: 10px;
        margin-right: 10px;
      }
      .debug {
        font-size: 12px;
        margin-left: 10px;
        width: 700px;
      }
    }
  }
}
