.graph {
  flex: 2 2;

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 70px;
    margin-bottom: 20px;
  }
  .content {
    padding-left: 0;
    padding-bottom: 30px;
  }

  .recharts-responsive-container {
    min-height: 400px;
  }

  .recharts-cartesian-axis-tick-value {
    fill: transparentize($color: #000000, $amount: 0.7);
  }
  .recharts-cartesian-axis-tick-line {
    stroke: transparentize($color: #000000, $amount: 0.7);
  }
}
