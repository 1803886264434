.form-input.language-picker {
  position: relative;
  display: flex;
  justify-content: space-between;
  button {
    color: var(--font-color);
    margin: 7px;
    padding: 0 4px 30px 4px;
    font-weight: normal;
    font-size: 13px;
    height: 36px;
    width: 68px;
    align-self: center;
    background-color: white;
    border: none;
    border-radius: 0px;
    text-align: left;
    transition: none;
    svg {
      position: relative;
      top: 4px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-right: 13px;
    }
  }
  button.selected {
    font-weight: bold;
    border-bottom: 6px solid rgb(163, 161, 251);
  }
  button.invalid {
    color: var(--error-color);
    &.selected {
      border-bottom: 6px solid var(--error-color);
    }
  }
  div.error {
    position: absolute;
    z-index: 1;
    left: 0;
    top: -10px;
    margin-top: 0px;
  }
}
