.customer-utils {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .customer-selector,
  .message-selector {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .select {
      margin-bottom: 10px;
    }
    button {
      padding: 0px 25px 0px 25px;
      margin-bottom: 10px;
      margin-right: 14px;
    }
  }
  .message-selector-title {
    margin-top: 24px;
  }
  .customer-utils-card {
    background-color: var(--card-background-color);
    box-shadow: var(--interactive-surface-box-shadow);
    border-radius: 4px;
    overflow: visible;
    height: 100%;
  }
}
