.common-loading {
  margin: auto;
  width: 200px;
  height: 250px;
  padding-bottom: 50px;

  strong {
    color: var(--loading-color);
    font-size: 200%;
    display: block;
    text-align: center;
    height: 50px;
  }

  /**
    * Ring loader adopted from
    * https://loading.io/css/
    */
  .lds-dual-ring {
    display: inline-block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .lds-dual-ring::after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 50px solid var(--loading-color);
    border-color: var(--loading-color) transparent var(--loading-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
