.avatar {
  .initials,
  .image,
  .placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 3px;
  }
  .placeholder {
    path {
      fill: rgba(0, 0, 0, 0.05);
    }
  }
  .image.loading {
    height: 0px;
    width: 0px;
    margin: 0px;
    padding: 0px;
  }
  .initials {
    width: 40px;
    text-align: center;
    line-height: 38px;
    font-size: 20px;
    background-color: var(--beacon-blue);
    color: var(--font-color-inverse);
  }
}
