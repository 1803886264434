.deployment {
  &-suxxess {
    .page-login .login-form .beacon-title img {
      width: 100%;
    }
    .common-header .logo-and-menu .logo img {
      width: 300px;
    }
  }
}
