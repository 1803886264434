.environment-tag {
  position: absolute;
  top: 70px;
  left: 0px;
  color: #fff;
  width: auto;
  padding: 0px 10px;
  line-height: 34px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0px 0px 5px 0px;
  background-color: #dc143c;

  &.local,
  &.localhost {
    background-color: #008000;
  }
  &.release {
    background-color: #800080;
  }
  &.develop {
    background-color: #dc143c;
  }
}

.common-header {
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  padding: var(--base-space) var(--base-space-2x);
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color-inverse);
  backdrop-filter: blur(30px) saturate(130%);
  backface-visibility: hidden;
  background-color: var(--navbar-background);
  z-index: 9999;

  .logo-and-menu {
    display: flex;
    margin-left: 20px;

    .hamburger {
      cursor: pointer;
      margin-top: 7px;
      align-self: center;
    }

    .logo {
      margin: auto 25px auto 22px;
      h1 {
        margin: auto;
        svg {
          path {
            fill: var(--font-color-inverse);
          }
        }
      }
    }

    .menu {
      margin: auto;
    }
  }

  .user-info {
    flex: 1 0;
    display: flex;
    margin-right: 40px;

    .links {
      display: flex;
      justify-content: space-between;
      margin: auto;
      flex-direction: row-reverse;
      flex: 1 0;
    }

    .user-menu {
      display: flex;

      .user-selector {
        margin-left: 16px;
        height: 40px;

        .dropdown_container {
          position: relative;
          max-width: initial;
          background-color: var(--navbar-background);
          border-radius: 4px;
          button {
            color: var(--font-color-inverse);
            width: 100%;
            text-align: left;
            text-transform: uppercase;
            background-color: var(--navbar-background);
          }
        }
      }

      .user-avatar {
        flex: 0 0 38px;
        user-select: none;
        pointer-events: none;
        box-sizing: border-box;
        font-size: 20px;
        text-align: center;
        line-height: 36px;
        margin: 2px 0px 0px 0px;
        display: block;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: var(--beacon-blue);
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 0;
    justify-content: space-between;
    backdrop-filter: none;

    .menu {
      display: none;
    }

    .beacon-logo {
      display: none;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      padding: var(--base-space);
      height: 70px;
    }
  }
}
