.location-overview-stores {
  .local-store-table th:nth-child(1),
  .local-store-table th:nth-child(2),
  .local-store-table td:nth-child(1),
  .local-store-table td:nth-child(2),
  .local-store-table td:nth-child(3),
  .local-store-table th:nth-child(3) {
    text-align: left;
  }
  .add-store-table th:nth-child(1),
  .add-store-table th:nth-child(2),
  .add-store-table th:nth-child(3),
  .add-store-table td:nth-child(1),
  .add-store-table td:nth-child(2),
  .add-store-table td:nth-child(3) {
    text-align: left;
  }

  .add-store-table th:nth-child(2),
  .add-store-table td:nth-child(2) {
    width: 15%;
  }
  .add-store-table th:nth-child(3),
  .add-store-table td:nth-child(3) {
    width: 20%;
  }

  .local-store-table td:nth-child(5) {
    font-weight: bold;
  }
  .add-store-table td:nth-child(5) {
    font-weight: bold;
  }
}
