.purchase-check {
  &.double {
    .first {
      flex: 0 0 175px !important;
    }
    .second {
      flex: 0 0 135px !important;
    }
  }
  .first {
    min-width: 145px;
  }
  .second {
    .input-row {
      justify-content: flex-start !important;
    }
    .amount {
      width: 75px;
      max-width: 75px;

      .error {
        margin: 3px 0px -8px -70px;
      }
    }
    .postfix {
      display: inline;
    }
  }
}
