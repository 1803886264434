.edit-category {
  transition: height;
  padding: 40px;
  min-width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-row {
    min-height: 37px;
    margin-bottom: 16px;
    .form-input {
      margin-bottom: 0px;
      &.language-picker {
        width: 320px;
      }
    }
  }
  .header {
    margin-bottom: 40px;
  }

  .loading {
    height: 600px;
    display: flex;
    justify-content: center;
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;
    min-width: 600px;

    .image {
      margin-top: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 200px;
    }
  }
}
