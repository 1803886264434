.areas-table {
  th:nth-child(1) {
    text-align: left;
  }

  td:nth-child(1) {
    text-align: left;
    font-weight: bold;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }
}
