.customer-segment-form {
  padding: 40px;
  form {
    margin-top: 20px;
  }

  .customer-segment-preview {
    .percentage-input {
      font-size: 12px;
      width: 70%;
      border-radius: 3px;
      border: 1px solid var(--input-border-color);
      background-color: var(--input-background-color);
      text-align: center;
      margin: 0px auto;
      padding: 2px;
      input {
        color: var(--font-color);
        outline: none;
        width: 2em;
        border-radius: 3px;
        border: none;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
