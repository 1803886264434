.page-content {
  .graph-and-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .graph-stats {
      flex: 1 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      .ui--card {
        flex: 1 1;
        .content {
          padding-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .touchpoint-simple-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ui--card {
      flex: 1 1;
    }
  }
}
