.customer-data {
  .revenue-statistics {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .ui--card {
      flex: 1 1 300px;
    }
  }
  .stats-card-simple {
    height: 140px;
  }
}
