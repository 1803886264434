@media screen and (min-width: 700px) {
  .campaign-form {
    min-width: 700px;
  }
}

.campaign-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;

  form {
    margin-top: 40px;
    .form-row.status {
      flex-direction: row-reverse;
      .select {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .campaign-form {
    width: 100%;
  }
}
