.campaign-card {
  .content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .figure {
      object-fit: cover;
      width: 100%;
      height: 230px;
      overflow: hidden;
      img {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .description {
    width: 100%;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-around;
    padding: var(--base-space-4x);
    height: 300px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }

    p {
      margin-top: var(--base-space-2x);
      margin-bottom: var(--base-space-2x);
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
    }

    .validity {
      font-size: 12px;
      color: #adaeb4;
      font-weight: bold;
    }

    strong.status {
      font-size: 12px;
      font-weight: bold;
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: auto;

      button {
        font-weight: bold;
        font-size: 14px;
      }

      .message-buttons {
        .view,
        .add {
          color: #676974;
        }
      }

      .edit-delete-buttons {
        .edit {
          color: #2580ec;
        }
        .delete {
          color: #ed585e;
        }
      }
    }
  }
}
