.location-heatmap {
  .map {
    .content {
      min-height: 600px;
      padding: 0px;
      .common-loading {
        padding-top: 180px;
        height: 430px;
      }
      .not-available-warning {
        height: 600px;
        width: 100%;
        display: flex;
        text-align: center;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        color: var(--message-text-light);
        font-size: 20px;
      }
    }
  }

  & > section {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .ui--card {
      flex: 1 1 300px;
    }
  }
}
