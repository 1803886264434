.page-content {
  .notifications-local {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .notifications-global {
    margin-top: 30px;
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
