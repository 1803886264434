.import-data {
  .tabs__tab-panel {
    position: relative;

    .toolbar {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 7000;
    }
  }

  .tabs__tab {
    background-color: white;

    &--selected {
      span {
        text-decoration: underline;
      }
      &.empty {
        background-color: var(--background-color-light);
        border-bottom-color: var(--background-color-light);
      }
    }
  }

  .content {
    min-height: 600px;
    padding: 0px;
  }
}
