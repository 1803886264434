@media screen and (min-width: 700px) {
  .store-form {
    width: 700px;
  }
}

.store-form {
  padding: 40px;

  label {
    margin-bottom: var(--base-space-2x);
  }

  form {
    h2 {
      color: var(--modal-title-color);
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0 10px 0;
    }

    margin-top: 20px;
    .form-row.dates {
      justify-content: flex-start;
      .form-input {
        width: 200px;
      }
    }

    .form-input.area {
      width: 200px;
    }
  }

  .image {
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
  }

  .image-upload-button {
    text-align: left;
    margin: 10px 0px 15px 7px;
  }
}

@media screen and (max-width: 960px) {
  .store-form {
    width: 100%;
  }
}
