.select.complex-selector {
  .select__control--menu-is-open {
    .select__placeholder {
      display: block;
    }
  }
  .select__menu-list {
    .select__option.select__option--is-selected {
      background-color: transparent;
      color: inherit;
      &.select__option--is-focused {
        background-color: var(--button-background-a01);
      }
      &.select__option:hover {
        background-color: var(--button-background-a01);
      }
    }
  }
}
