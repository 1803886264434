.top-cities table {
  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    font-weight: bold;
  }

  th:nth-child(2),
  td:nth-child(2) {
    max-width: 160px;
    min-width: 100px;
    width: 100px;
  }
}
