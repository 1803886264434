.diagnostics-table {
  padding: 40px;
  min-width: 95vw;
  height: 90vh;

  header {
    h1 {
      font-size: 21px;
      font-weight: bold;
      color: #676974;
      margin-top: var(--base-space-2x);
    }
    .description {
      line-height: 1.7;
      font-size: 13px;
      color: #adaeb4;
      max-width: 75vw;
    }
  }

  th {
    text-align: left;
    max-width: fit-content;
    position: sticky;
    top: 0;
  }

  td {
    padding: 10px 0px 10px 15px;
    text-align: left;
    max-width: fit-content;
  }

  tr:nth-child(even) {
    background-color: #f0f0f0;
    color: #fff;
  }

  .top-right-side {
    padding: 4px 0px;

    .saved-headers {
      margin-left: 20px;
      min-width: 10em;
    }
    button.delete {
      float: right;
      width: 100px;
      height: 33px;
      font-size: 10px;
      padding: 0px 2px;
      margin: 5px 10px;
    }
  }
}
